<template>
  <mobile-screen
    :header="true"
    screen-class="resources-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="Boolean(false)"
        :helpOnlineRoute="{
          name: 'r_plan-resources-filters-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("search", "filters", "search-filters") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section v-if="sections && sections.length">
      <ul
        v-for="section in sections"
        :key="section"
        class="clebex-item-section overflow-hidden"
      >
        <li
          class="clebex-item-section-item"
          :class="collapse[section] ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ displayLabelName("resources", "search", section) }}
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse[section]
                  ? (collapse[section] = false)
                  : (collapse[section] = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <section
          v-if="section === 'levels' && levels && levels.data"
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="option in levels.data.filter(item => item.resources_count)"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter(section, option.id)"
            >
              <span class="label">
                <span class="text ellipsis">
                  <span class="highlight">{{
                    option.translations.find(el => el.locale === locale)
                      ? option.translations.find(el => el.locale === locale)
                          .attributes.name
                      : option.name
                  }}</span>
                  <span v-if="option.full_path && option.full_path.length"
                    >, {{ option.full_path.join(", ") }}</span
                  >
                </span>
              </span>
              <span
                v-if="selectedLevelIds.indexOf(option.id) !== -1"
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="
            section === 'functions' &&
              resourceFunctionTypes &&
              resourceFunctionTypes.data
          "
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="option in resourceFunctionTypes.data"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter(section, option.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  option.name
                }}</label>
              </div>
              <span
                v-if="selectedFunctionTypeIds.indexOf(option.id) !== -1"
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="
            section === 'types' &&
              ((!planSearchFilters && resourceTypes && resourceTypes.data) ||
                (planSearchFilters && filterResourceTypes))
          "
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="option in !planSearchFilters
              ? nonSearchableItemsHidden
                ? resourceTypes.data.filter(item => item.is_searchable)
                : resourceTypes.data
              : filterResourceTypes"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter(section, option.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  option.name
                }}</label>
              </div>
              <span
                v-if="selectedTypeIds.indexOf(option.id) !== -1"
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="section === 'models' && resourceModels && resourceModels.data"
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="option in nonSearchableItemsHidden
              ? resourceModels.data.filter(item => item.is_searchable)
              : resourceModels.data"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter(section, option.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  option.name
                }}</label>
              </div>
              <span
                v-if="selectedModelIds.indexOf(option.id) !== -1"
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="section === 'capacity'"
          :class="collapse[section] ? 'collapsed' : 'expanded one'"
        >
          <div class="capacity-filter-input-section clebex-item-section">
            <button
              class="capacity-filter-btn decrease-capacity"
              @click="decreaseCapacity"
            >
              -
            </button>
            <div class="capacity-filter-input-wrapper clebex-section-input">
              <input
                type="text"
                v-model="selectedCapacity"
                @input="preventText"
              />
            </div>
            <button
              class="capacity-filter-btn increase-capacity"
              @click="increaseCapacity"
            >
              +
            </button>
          </div>
        </section>
        <section
          v-if="
            section === 'attributes' &&
              ((!planSearchFilters &&
                resourceAttributes &&
                resourceAttributes.data) ||
                (planSearchFilters &&
                  filterResourceAttributes &&
                  filterResourceAttributes.length))
          "
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="option in !planSearchFilters
              ? nonSearchableItemsHidden
                ? resourceAttributes.data.filter(item => item.is_searchable)
                : resourceAttributes.data
              : filterResourceAttributes"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter(section, option.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  option.name
                }}</label>
              </div>
              <span
                v-if="selectedAttributesIds.indexOf(option.id) !== -1"
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
      </ul>
    </section>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearAll()">
              {{
                displayLabelName("resources", "resource-filters", "clear-all")
              }}
            </button>
          </li>
          <li v-if="!planSearchFilters" class="action">
            <button
              class="action-btn"
              @click="
                $store.commit('searchFiltersResources/activateSearch', true, {
                  root: true
                })
              "
            >
              {{ displayLabelName("resources", "resource-filters", "apply") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SearchFiltersResourceContainers",
  mixins: [helpOnlineMixin],
  data() {
    return {
      collapse: {},
      helpSlug: null
    };
  },
  computed: {
    locale() {
      return getLang();
    },
    sections() {
      return this.$store.state["searchFiltersResources"].sections;
    },
    selectedSection() {
      return this.$store.state["searchFiltersResources"].selectedSection;
    },
    selectedFunctionTypeIds() {
      return this.$store.state["searchFiltersResources"]
        .selectedFunctionTypeIds;
    },
    selectedLevelIds() {
      return this.$store.state["searchFiltersResources"].selectedLevelIds;
    },
    selectedTypeIds() {
      return this.$store.state["searchFiltersResources"].selectedTypeIds;
    },
    selectedModelIds() {
      return this.$store.state["searchFiltersResources"].selectedModelIds;
    },
    selectedAttributesIds() {
      return this.$store.state["searchFiltersResources"].selectedAttributesIds;
    },
    nonSearchableItemsHidden() {
      if (
        this.$route.name ===
        "r_edit-resource-add-resources-to-container-search-filters"
      ) {
        return false;
      } else {
        return true;
      }
    },
    planSearchFilters() {
      if (
        [
          "r_plan-search-filters",
          "r_plan-resources-filters-help-online"
        ].includes(this.$route.name)
      ) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState("resource", [
      "resourceModels",
      "resourceTypes",
      "resourceAttributes",
      "resourceFunctionTypes",
      "filterResourceTypes",
      "filterResourceAttributes"
    ]),
    ...mapState("level", ["levels"]),
    ...mapState("plan", ["selectedLevel"]),
    ...mapState("search", ["searchQuery"]),
    selectedCapacity: {
      get() {
        return this.$store.state["searchFiltersResources"].selectedCapacity;
      },
      set(val) {
        this.setSelectedCapacity(val.replace(/[^0-9]/g, ""));
      }
    },
    payload() {
      if (this.selectedLevel) {
        return {
          filters: [
            {
              type: "level",
              value: this.selectedLevel.id,
              options: {
                mode: "INDIRECT"
              }
            },
            {
              type: "resource_function_type",
              value: [1]
            },
            {
              type: "resource_model",
              value: [2, 3]
            },
            {
              type: "query",
              value: this.searchQuery ? this.searchQuery : null
            },
            {
              type: "resource_type",
              value: this.selectedTypeIds.length ? this.selectedTypeIds : null
            },
            {
              type: "resource_attribute",
              value: this.selectedAttributesIds.length
                ? this.selectedAttributesIds
                : null
            }
          ],
          includes: ["resource_types", "resource_attributes"]
        };
      }
      return null;
    }
  },
  created() {
    if (this.planSearchFilters) {
      this.getResourceFilters(this.payload);
    }
    if (this.sections.indexOf("types") !== -1) {
      this.getResourceTypes();
    }
    if (this.sections.indexOf("attributes") !== -1) {
      this.getResourceAttributes();
    }
    if (this.sections.indexOf("models") !== -1) {
      this.getResourceModels();
    }
    if (this.sections.indexOf("functions") !== -1) {
      this.getResourceFunctionTypes();
    }
    if (this.sections.indexOf("levels") !== -1) {
      this.getLevels({
        depth: "BOTTOM",
        includes: ["full_path", "resources_count"]
      });
    }
  },
  methods: {
    ...mapActions("resource", [
      "getResourceTypes",
      "getResourceModels",
      "getResourceAttributes",
      "getResourceFunctionTypes",
      "getResourceFilters"
    ]),
    ...mapActions("level", ["getLevels"]),
    setSelectedSection(section) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedSection`,
        section,
        { root: true }
      );
    },
    setSelectedTypeIds(id) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedTypeIds`,
        id,
        { root: true }
      );
    },
    setSelectedFunctionTypeIds(id) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedFunctionTypeIds`,
        id,
        { root: true }
      );
    },
    setSelectedLevelIds(id) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedLevelIds`,
        id,
        { root: true }
      );
    },
    setSelectedModelIds(id) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedModelIds`,
        id,
        { root: true }
      );
    },
    setSelectedAttributeIds(id) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedAttributeIds`,
        id,
        { root: true }
      );
    },
    setSelectedCapacity(id) {
      this.$store.dispatch(
        `${"searchFiltersResources"}/setSelectedCapacity`,
        id,
        { root: true }
      );
    },
    selectSection(section) {
      if (section !== this.selectedSection) {
        this.setSelectedSection(section);

        if (section === "types" && !this.resourceTypes) {
          this.getResourceTypes();
        }

        if (section === "models" && !this.resourceModels) {
          this.getResourceModels();
        }

        if (section === "attributes" && !this.resourceAttributes) {
          this.getResourceAttributes();
        }
        if (section === "functions" && !this.resourceFunctionTypes) {
          this.getResourceAttributes();
        }
        if (section === "levels" && !this.levels) {
          this.getLevels({
            depth: "BOTTOM",
            includes: ["full_path", "resources_count"]
          });
        }
      }
    },
    selectFilter(type, id) {
      if (type === "types") {
        this.setSelectedTypeIds(id);
      } else if (type === "models") {
        this.setSelectedModelIds(id);
      } else if (type === "attributes") {
        this.setSelectedAttributeIds(id);
      } else if (type === "capacity") {
        this.setSelectedCapacity(id);
      } else if (type === "functions") {
        this.setSelectedFunctionTypeIds(id);
      } else if (type === "levels") {
        this.setSelectedLevelIds(id);
      }
    },
    selectedOptionInList(id, section) {
      if (section === "type") {
        return this.selectedTypeIds.indexOf(id) !== -1;
      } else if (section === "models") {
        return this.selectedModelIds.indexOf(id) !== -1;
      } else if (section === "attributes") {
        return this.selectedAttributesIds.indexOf(id) !== -1;
      } else if (section === "functions") {
        return this.selectedFunctionTypeIds.indexOf(id) !== -1;
      } else if (section === "levels") {
        return this.selectedLevelIds.indexOf(id) !== -1;
      }
    },
    preventText(e) {
      if (/[^0-9]/g.test(e.target.value)) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
      }
    },
    increaseCapacity() {
      if (
        Number(this.selectedCapacity) ||
        Number(this.selectedCapacity) === 0
      ) {
        this.setSelectedCapacity(Number(this.selectedCapacity) + 1);
      } else {
        this.setSelectedCapacity(1);
      }
    },
    decreaseCapacity() {
      if (Number(this.selectedCapacity - 1) >= 0) {
        this.setSelectedCapacity(Number(this.selectedCapacity) - 1);
      }
    },
    clearAll() {
      this.$store.commit(`${"searchFiltersResources"}/clearAllFilters`);
    }
  },
  watch: {
    selectedTypeIds() {
      if (this.planSearchFilters) {
        this.getResourceFilters(this.payload);
        this.$store.commit(`${"searchFiltersResources"}/activateSearch`, true, {
          root: true
        });
      }
    },
    selectedAttributesIds() {
      if (this.planSearchFilters) {
        this.getResourceFilters(this.payload);
        this.$store.commit(`${"searchFiltersResources"}/activateSearch`, true, {
          root: true
        });
      }
    },
    searchQuery() {
      if (this.planSearchFilters) {
        this.getResourceFilters(this.payload);
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    },
    searchFiltersStore: {
      type: String,
      required: true
    },
    disableModel: {
      type: Boolean,
      required: false
    }
  },
  beforeUnmount() {
    this.$store.commit("resource/setFilterResourceTypes", null, {
      root: true
    });
    this.$store.commit("resource/setFilterResourceAttributes", null, {
      root: true
    });
  }
};
</script>
